import React, { useState } from "react";
import styles from "./styles.module.scss";
import theme from "../../Styles/theme.scss";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
// API layer
import { postAIPrompt } from "@intelligentlilli/api-layer";
// Components
import CTAButton from "../../Components/CTAButton";
import { Field, Form } from "react-final-form";
import Select from "react-select";
// State
import { setAIPrompts } from "../../State/slices/ai-settings-slice";
import NewStyleModal from "../NewStyleModal";

const AIPromptEntry = ({ promptList, getPromptErr }) => {
  const dispatch = useDispatch();
  const server = useSelector((state) => state.session.server);
  const user = useSelector((state) => state.user);
  const productionPromptId = useSelector(
    (state) => state.ai.settings.productionPromptString
  );
  const [openModal, setOpenModal] = useState(false);

  const promptDataTypes = useSelector((state) => state.ai.settings.promptTypes);
  const navigate = useNavigate();
  const [selectedPrompt, setSelectedPrompt] = useState();
  const [postPromptErr, setPostPromptErr] = useState();
  const [isBorderChanged, setIsBorderChanged] = useState(false);

  const changeBorderColor = () => {
    setIsBorderChanged(true);
    setTimeout(() => {
      setIsBorderChanged(false);
    }, 900);
  };

  const onSubmit = (values, form) => {
    setPostPromptErr(null);
    postAIPrompt(server, {
      prompt: values.prompt,
      user: `${user.username}`,
      prompt_id: `${values.title}@${new Date().toISOString()}`,
      description: values.description,
      title: values.title,
    })
      .then((res) => {
        if (!res.ok) {
          if (res.status === 401) navigate("/login");
          if (res.status === 400) {
            setPostPromptErr(
              "Error: prompt could not be added. Please try again."
            );
          }
          setPostPromptErr(
            "Error: Unable to create new prompt. Please try again."
          );
          return;
        } else {
          form.reset();
          // Return the new list of prompts; set the newly created prompt as selected
          // Highlight the change via the border to indicate success
          dispatch(
            setAIPrompts({
              productionPromptId: productionPromptId,
              responseBody: res.body,
            })
          );
          setSelectedPrompt();
          changeBorderColor();
        }
      })
      .catch((err) => {
        console.log("postPromptError", err);
        setPostPromptErr("Unable to create new prompt. Please try again");
      });
  };

  const toggleModal = () => {
    setOpenModal(!openModal);
  };

  return (
    <div className={styles.outer_container}>
      <div className={styles.container}>
        <p className={styles.heading_text}>See previous prompts</p>
        <Select
          placeholder="Select prompt"
          isSearchable
          options={promptList}
          name="prompts"
          onChange={setSelectedPrompt}
          components={{
            IndicatorSeparator: () => null, // Removes the "|" to the left of the drop-down arrow
          }}
          styles={{
            control: (baseStyles, { isFocused }) => ({
              ...baseStyles,
              borderColor: isFocused
                ? theme.primary3
                : isBorderChanged
                  ? theme.expected2
                  : theme.neutral3,
              marginTop: 4,
              fontSize: theme.bodyMedium,
              color: theme.neutral7,
              borderRadius: 8,
              ":hover": {
                borderColor: theme.primary3,
              },
              boxShadow: "none",
            }),
            option: (styles, { isFocused }) => ({
              ...styles,
              fontSize: "16px",
              backgroundColor: isFocused ? theme.neutral2 : null,
              color: theme.neutral7,
              // adjust background colour when mouse button is pressed
              ":active": {
                backgroundColor: theme.neutral3,
              },
            }),
          }}
        />
        {selectedPrompt ? (
          <div className={styles.prompt_left_container}>
            <p>
              <span className={styles.bold_text}>Title:</span>{" "}
              {selectedPrompt.title}
            </p>
            <p>
              <span className={styles.bold_text}>Prompt ID:</span>{" "}
              {selectedPrompt.prompt_id}
            </p>
            <p>
              <span className={styles.bold_text}>Creator:</span>{" "}
              {selectedPrompt.user}
            </p>
            <p>
              <span className={styles.bold_text}>Description</span>{" "}
              {selectedPrompt.description}
            </p>
            <p className={styles.bold_text}>Prompt:</p>
            <p>{selectedPrompt.prompt}</p>
          </div>
        ) : null}
        {getPromptErr ? (
          <div>
            <p className={styles.error_text}>{getPromptErr}</p>
          </div>
        ) : null}
      </div>
      <div className={styles.container}>
        <div className={styles.header}>
          <p className={styles.heading_text}>Create a new prompt</p>
          <p onClick={toggleModal} className={styles.information_link}>
            What datatypes are available?
          </p>
        </div>
        <Form
          onSubmit={(values, form) => onSubmit(values, form)}
          validate={(values) => {
            const errors = {};
            if (!values.title) {
              errors.title = "Please provide a title";
            }
            if (!values.description) {
              errors.description = "Please provide a description";
            }
            if (!values.prompt) {
              errors.prompt = "Please provide a prompt";
            }
            return errors;
          }}
          render={({ handleSubmit, submitting, pristine, valid }) => (
            <form onSubmit={handleSubmit} className={styles.form}>
              <label htmlFor="prompt_entry">Prompt:</label>
              <Field
                required
                component="textarea"
                className={styles.prompt_textarea}
                name="prompt"
                id="prompt_entry"
                placeholder="Enter your prompt"
              />
              <label htmlFor="description_entry">Description:</label>
              <Field
                required
                component="input"
                name="description"
                id="description_entry"
                placeholder="Enter the prompt description"
                className={styles.prompt_description}
              />
              <label htmlFor="title_entry">Title:</label>
              <Field
                required
                component="input"
                name="title"
                id="title_entry"
                placeholder="Enter the prompt title"
                className={styles.prompt_description}
              />
              {postPromptErr ? (
                <div>
                  <p className={styles.error_text}>{postPromptErr}</p>
                </div>
              ) : null}
              <CTAButton
                type="submit"
                disabled={submitting || pristine || !valid}
              >
                Create
              </CTAButton>
            </form>
          )}
        />
      </div>
      {openModal ? (
        <NewStyleModal showCloseIcon hide={toggleModal}>
          <div className={styles.modal_content}>
            The following data types are available:
            {promptDataTypes ? (
              <ul className={styles.list}>
                {promptDataTypes.map((datatype) => (
                  <li key={datatype}>
                    {"${"}
                    {datatype}
                    {"}"}
                  </li>
                ))}
              </ul>
            ) : null}
            Copy and paste the required data type into the text area field from
            the above.
          </div>
        </NewStyleModal>
      ) : null}
    </div>
  );
};

export default AIPromptEntry;
