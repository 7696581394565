import React from "react";
import SUDayView from "../SUDayView";

const BehaviourOverviewDay = ({ events, insights, hubId, orgId }) => {
  return (
    <SUDayView
      currentTab={"Overview"}
      insights={insights}
      events={events}
      noData={false}
      isNewVariant={true}
      isOverview={true}
      isNighttime={false}
      noSplitting={false}
      hubId={hubId}
      orgId={orgId}
    />
  );
};

export default BehaviourOverviewDay;
