import React, { useState } from "react";
import { useSelector } from "react-redux";
import styles from "./styles.module.scss";
import theme from "../../Styles/theme.scss";
import { isToday } from "date-fns";
import PrimaryButton from "../PrimaryButton";
import { ThumbsDownIcon, ThumbsUpIcon } from "../../Styles/Icons/DesignSystem";
import { putFeedbackOnAISummary } from "@intelligentlilli/api-layer";

import { SERVER } from "../../Services/config";

const AISummary = ({
  aiSummary,
  isLoading,
  hasAiSummaryForToday,
  isHelpful,
  setIsHelpful,
  hubId,
  urlStartDateString,
}) => {
  const isItToday = isToday(new Date(urlStartDateString));
  const userOrgId = useSelector((state) => state.user?.organisationId);
  const userKey = useSelector((state) => state.user?.key);
  const [errorPostingFeedback, setErrorPostingFeedback] = useState(null);

  const sendFeedback = (isHelpful, localStateIsHelpful) => {
    if (isHelpful === localStateIsHelpful) return;
    setErrorPostingFeedback(null);
    setIsHelpful(isHelpful);
    putFeedbackOnAISummary({
      server: SERVER,
      isHelpful,
      hubId: hubId,
      summaryId: aiSummary?.id,
      orgId: userOrgId,
      date: urlStartDateString,
      origin: "web",
      cookie: "",
    })
      .then((res) => {
        if (res.ok) {
          const isHelpful = res.body.thumbsUp.includes(userKey);
          const noRatingGiven =
            !isHelpful && res.body.thumbsDown.includes(userKey) === false;
          setIsHelpful(noRatingGiven ? null : isHelpful);
          return;
        }
      })
      .catch((err) => {
        console.error("sendFeedback error:", err);
        setErrorPostingFeedback("Error posting feedback, please try again.");
      });
  };

  return (
    <div className={styles.ai_container}>
      <div className={styles.top}>
        <div className={styles.ai_title}>Daily summary</div>
        <div className={styles.ai_subtitle}>A summary of this user's day</div>
      </div>
      <div className={styles.summary_container}>
        <div className={styles.ai_content}>
          {isItToday
            ? "AI summaries are generated at the end of the day, please check back tomorrow."
            : isLoading
              ? "Loading..."
              : aiSummary?.summary
                ? aiSummary.summary
                : "No AI summary is available today."}
        </div>
      </div>
      <div className={styles.error_posting_feedback}>
        {errorPostingFeedback && errorPostingFeedback}
      </div>
      {hasAiSummaryForToday && !isItToday && !isLoading && (
        <div className={styles.feedback_buttons}>
          <PrimaryButton
            onClick={() => sendFeedback(true, isHelpful)}
            startIcon={
              <ThumbsUpIcon
                width={24}
                color={isHelpful ? "white" : theme.neutral6}
              />
            }
            className={isHelpful ? styles.helpful : styles.default}
            hoverColour={isHelpful && "#7dc62950"} // 50% opacity expected2 color
          >
            Useful
          </PrimaryButton>
          <PrimaryButton
            onClick={() => sendFeedback(false, isHelpful)}
            startIcon={
              <ThumbsDownIcon
                width={24}
                color={isHelpful === false ? "white" : theme.neutral6}
              />
            }
            className={isHelpful === false ? styles.unhelpful : styles.default}
            style={{ marginLeft: 10 }}
            hoverColour={isHelpful === false && theme.risk2}
          >
            Not useful
          </PrimaryButton>
        </div>
      )}
    </div>
  );
};

export default AISummary;
