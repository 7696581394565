import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

export const useGetDropDownOrgAndHubs = () => {
  const organisations = useSelector(
    (state) => state.ai?.organisationsFormatted
  );
  // Drop-down options
  const [orgs, setOrgs] = useState(organisations || []);
  const [hubs, setHubs] = useState(organisations?.[0]?.hubs || []);
  // selected
  const [selectedOrg, setSelectedOrg] = useState(orgs?.[0] || null);
  const [selectedHub, setSelectedHub] = useState(hubs?.[0] || null);

  useEffect(() => {
    if (organisations) {
      setOrgs(organisations);
    }
  }, [organisations]);

  // ---- Handle UI changes - organization or hub change selection ----
  const handleOrgChange = (selectedOption) => {
    const org = orgs.find((org) => org.id === selectedOption.id);
    setSelectedOrg(org);
    setHubs(org.hubs);
    setSelectedHub(org.hubs[0]);
  };

  const handleHubChange = (selectedOption) => {
    const hub = hubs.find((hub) => hub.id === selectedOption.id);
    setSelectedHub(hub);
  };

  return {
    orgs,
    hubs,
    selectedOrg,
    selectedHub,
    handleOrgChange,
    handleHubChange,
    setSelectedOrg,
    setSelectedHub,
    setOrgs,
    setHubs,
  };
};
