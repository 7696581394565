import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getPrompts, getAISettings } from "@intelligentlilli/api-layer";
// Components
import Page from "../../Components/Page";
import SettingsHeader from "../../Components/SettingsHeader";
import AISettingsDropDown from "../../Components/AISettingsDropDown";
import AIPromptEntry from "../../Components/AIPromptEntry";
import AIComparePrompts from "../../Components/AIComparePrompts";
// State
import {
  setAISettings,
  setAIPrompts,
  setFormattedOrganisations,
} from "../../State/slices/ai-settings-slice";

const AiSettings = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const server = useSelector((state) => state.session.server);
  const productionPromptId = useSelector(
    (state) => state.ai.settings.productionPromptId
  );
  const promptList = useSelector((state) => state.ai.prompts);
  // Get AI settings
  const [settingsFetched, setSettingsFetched] = useState(false);
  const [aiSettingsError, setAISettingsError] = useState(null);
  // Get Prompts
  const [getPromptErr, setGetPromptErr] = useState();

  useEffect(() => {
    getAISettings({ server, origin: "web", cookie: "" })
      .then((res) => {
        if (!res.ok) {
          if (res.status === 401) navigate("/login");
          setAISettingsError(
            "Could not load settings. Refresh the screen to try again."
          );
          return;
        }
        if (res.body) {
          dispatch(setAISettings(res.body));
          dispatch(setFormattedOrganisations(res.body));
        }
      })
      .catch((err) => {
        console.error("getAISettings error:", err);
        setAISettingsError(
          "Could not load settings. Refresh the screen to try again."
        );
      })
      .finally(() => setSettingsFetched(true));

    if (productionPromptId) {
      getPrompts({ server, origin: "web", cookie: "" })
        .then((res) => {
          if (!res.ok) {
            if (res.status === 401) navigate("/login");
            if (res.status === 400) {
              setGetPromptErr(
                "Error: prompts could not be retrieved. Refresh the page and try again."
              );
            }
            return;
          }
          if (res.ok && res.body) {
            dispatch(
              setAIPrompts({
                productionPromptId: productionPromptId,
                responseBody: res.body,
              })
            );
            setGetPromptErr(null);
          }
        })
        .catch((err) => {
          console.log("getPromptErr", err);
          setGetPromptErr("Unable to get prompts. Please refresh the page");
        });
    }
  }, [server, navigate, dispatch, productionPromptId]);

  return (
    <Page>
      <div className={styles.content}>
        <SettingsHeader
          title={"AI Summary Settings"}
          description={"This is only available for admin roles"}
        />
        <div className={styles.settings_section}>
          <div className={styles.ai_settings_title}>
            Enable or Disable AI summaries
          </div>
          <div className={styles.divider_bar}></div>
          <AISettingsDropDown
            aiSettingsError={aiSettingsError}
            settingsFetched={settingsFetched}
          />
        </div>
        <div className={styles.prompt_creation_section}>
          <div className={styles.ai_prompt_form}>AI Prompt Form</div>
          <div className={styles.divider_bar}></div>
          <AIPromptEntry promptList={promptList} getPromptErr={getPromptErr} />
        </div>
        <div className={styles.comparison_section}>
          <div className={styles.compare_prompts}>Compare prompts</div>
          <div className={styles.divider_bar}></div>
          <AIComparePrompts
            promptList={promptList}
            getPromptErr={getPromptErr}
          />
        </div>
      </div>
    </Page>
  );
};

export default AiSettings;
