import React from "react";
import Select from "react-select";
import theme from "../../Styles/theme.scss";

const DropDownSelect = ({
  options,
  onChange,
  placeholder,
  name,
  getOptionLabel,
  getOptionValue,
  menuPlacement,
  marginRight,
  width,
}) => {
  return (
    <Select
      placeholder={placeholder}
      isSearchable
      options={options}
      name={name}
      onChange={onChange}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      menuPlacement={menuPlacement || "bottom"}
      components={{
        IndicatorSeparator: () => null, // Removes the "|" to the left of the drop-down arrow
      }}
      styles={{
        control: (baseStyles, { isFocused }) => ({
          ...baseStyles,
          borderColor: isFocused ? theme.primary3 : theme.neutral3,
          marginTop: 4,
          fontSize: theme.bodyMedium,
          color: theme.neutral7,
          borderRadius: 8,
          ":hover": {
            borderColor: theme.primary3,
          },
          boxShadow: "none",
          width: width,
          marginRight: marginRight,
        }),
        option: (styles, { isFocused }) => ({
          ...styles,
          fontSize: "16px",
          backgroundColor: isFocused ? theme.neutral2 : null,
          color: theme.neutral7,
          ":active": {
            backgroundColor: theme.neutral3,
          },
        }),
      }}
    />
  );
};

export default DropDownSelect;
