import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  settings: {
    aiSummariesEnabled: null,
    hubsDisabled: [],
    offForAll: null,
    organisations: [],
    organisationsDisabled: [],
    promptTypes: [],
    productionPromptId: null,
  },
  organisationsFormatted: [],
  prompts: [],
};

const aiSettingsSlice = createSlice({
  name: "ai",
  initialState,
  reducers: {
    setAIPrompts(state, action) {
      const { productionPromptId, responseBody } = action.payload;
      const formatted = responseBody
        .map((prompt) => ({
          label: `${productionPromptId === `${prompt.prompt_id}.txt` ? "(PROD) - " : ""} ${prompt.title} - ${prompt.user}`,
          ...prompt,
        }))
        .sort((a, b) =>
          a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1
        );
      state.prompts = formatted;
    },
    setAISettings(state, action) {
      state.settings = action.payload;
    },
    updateIsOnForAllOrgs(state, action) {
      state.settings.offForAll = action.payload;
    },
    setFormattedOrganisations(state, action) {
      const formattedData = action.payload.organisations.map((org) => ({
        id: org.orgId,
        value: org.orgId,
        label: org.orgId,
        aiSummariesEnabled: org.aiSummariesEnabled,
        hubs: org.hubs.map((hub) => ({
          id: hub.id,
          value: hub.userstring,
          label: `${hub.userstring} - hubId: ${hub.id}`,
          aiSummariesEnabled: hub.aiSummariesEnabled,
        })),
      }));
      state.organisationsFormatted = formattedData;
    },
  },
});

export const {
  setAIPrompts,
  setAISettings,
  updateIsOnForAllOrgs,
  setFormattedOrganisations,
} = aiSettingsSlice.actions;
export default aiSettingsSlice.reducer;
