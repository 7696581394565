import React, { useState } from "react";
import styles from "./styles.module.scss";
import { useSelector } from "react-redux";
// API
import { getAISummary } from "@intelligentlilli/api-layer";
// Components
import CTAButton from "../../Components/CTAButton";
import DropDownSelect from "../../Components/DropDownSelect";
// Hooks
import { useGetDropDownOrgAndHubs } from "../../Services/Hooks";

const AIComparePrompts = ({ promptList, getPromptErr }) => {
  const server = useSelector((state) => state.session.server);
  // Drop down list for orgs and hubs
  const {
    orgs,
    hubs,
    selectedOrg,
    selectedHub,
    handleOrgChange,
    handleHubChange,
  } = useGetDropDownOrgAndHubs();
  const [date, setDate] = useState();
  const hasAllParams = selectedOrg && selectedHub && date;

  const [selectedPrompt, setSelectedPrompt] = useState(null);
  const [selectedSecondPrompt, setSelectedSecondPrompt] = useState(null);

  const [firstSummary, setFirstSummary] = useState();
  const [secondSummary, setSecondSummary] = useState();
  const [firstPromptErr, setFirstPromptErr] = useState();
  const [secondPromptErr, setSecondPromptErr] = useState();
  const [firstLoading, setFirstLoading] = useState();
  const [secondLoading, setSecondLoading] = useState();

  const generateSummary = async (promptId, isFirst) => {
    isFirst ? setFirstLoading(true) : setSecondLoading(true);
    try {
      const response = await getAISummary({
        server,
        hubId: selectedHub.id,
        orgId: selectedOrg.id,
        date,
        origin: "web",
        cookie: "",
        promptId: promptId,
      });
      if (response.ok && response.body) {
        isFirst
          ? setFirstSummary(response.body)
          : setSecondSummary(response.body);
        isFirst ? setFirstPromptErr(null) : setSecondPromptErr(null);
      }
    } catch (error) {
      console.error("Error fetching AI summary:", error);
      const errorMessage =
        "Error fetching AI summary, try again or select another prompt";
      isFirst
        ? setFirstPromptErr(errorMessage)
        : setSecondPromptErr(errorMessage);
      isFirst ? setFirstSummary(null) : setSecondSummary(null);
    } finally {
      isFirst ? setFirstLoading(false) : setSecondLoading(false);
    }
  };

  return (
    <div className={styles.main_container}>
      <div className={styles.set_up_container}>
        <div className={styles.set_up_input}>
          <label>Organisation Id:</label>
          <DropDownSelect
            options={orgs}
            onChange={handleOrgChange}
            placeholder="Org Id"
            name="org-id"
            width="150px"
          />
        </div>
        <div className={styles.set_up_input}>
          <label>Hub Id:</label>
          <DropDownSelect
            options={hubs}
            onChange={handleHubChange}
            placeholder="Select a hub"
            name="hub-id"
            width="400px"
          />
        </div>
        <div className={styles.set_up_input}>
          <label for="date-select">Date:</label>
          <input
            type="date"
            id="date-select"
            name="date-select"
            value={date}
            onChange={(e) => setDate(e.target.value)}
            className={styles.date_input}
          />
        </div>
      </div>
      <div className={styles.prompts_container}>
        <div className={styles.single_prompt}>
          <div className={styles.drop_down_outer}>
            <div className={styles.drop_down_inner}>
              <label>Select prompt:</label>
              <DropDownSelect
                options={promptList}
                onChange={setSelectedPrompt}
                placeholder="Select prompt"
                name="prompts"
              />
            </div>
            <CTAButton
              onClick={() => generateSummary(selectedPrompt.prompt_id, true)}
              style={{ marginTop: "16px" }}
              disabled={!hasAllParams || !selectedPrompt?.prompt_id}
            >
              Generate
            </CTAButton>
          </div>
          <p>
            {getPromptErr ? "Sorry, we could not get the list of prompts" : ""}
          </p>
          <div>
            {firstLoading
              ? "Loading..."
              : firstSummary
                ? firstSummary.summary
                : firstPromptErr
                  ? firstPromptErr
                  : null}
          </div>
        </div>
        <div className={styles.single_prompt}>
          <div className={styles.drop_down_outer}>
            <div className={styles.drop_down_inner}>
              <label>Select prompt to compare:</label>
              <DropDownSelect
                options={promptList}
                onChange={setSelectedSecondPrompt}
                placeholder="Select prompt"
                name="compare-prompts"
              />
            </div>
            <CTAButton
              style={{ marginTop: "16px" }}
              onClick={() =>
                generateSummary(selectedSecondPrompt.prompt_id, false)
              }
              disabled={!hasAllParams || !selectedSecondPrompt}
            >
              Generate
            </CTAButton>
          </div>
          <p>
            {getPromptErr ? "Sorry, we could not get the list of prompts" : ""}
          </p>
          <div>
            {secondLoading
              ? "Loading..."
              : secondSummary
                ? secondSummary.summary
                : secondPromptErr
                  ? secondPromptErr
                  : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AIComparePrompts;
