import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { mobileBreakpoint, SERVER } from "../../Services/config";
import styles from "./styles.module.scss";
// Components
import { useViewport } from "../ViewportProvider";
import Toggle from "../Toggle";
import EventLogNew from "../EventLogNew";
import NoDataAvailable from "../NoDataAvailable";
import NoDataBox from "../NoDataBox";
import Insight from "../Insight";
import AISummary from "../AISummary";
// Utils
import { format, isToday, parse } from "date-fns";
import { getUrlParameter } from "@intelligentlilli/lilli-utils";
import { getAISummary } from "@intelligentlilli/api-layer";

const SUDayView = ({
  currentTab,
  isBathroom,
  noSplitting,
  events,
  noData,
  insights,
  insightsContext,
  children,
  isNewVariant,
  isOverview,
  isNighttime,
  hubId,
  orgId,
}) => {
  const [aiSummary, setAISummary] = useState(null);
  const [isLoadingAISummary, setIsLoadingAISummary] = useState(false);
  const [isHelpful, setIsHelpful] = useState(null);
  const userKey = useSelector((state) => state.user?.key);

  const urlStartDateString =
    getUrlParameter("start") || format(new Date(), "yyyy-MM-dd");

  const hasAiSummaryForToday =
    aiSummary?.summary !== "No AI summary available for this day." || false;

  useEffect(() => {
    const isNotToday = !isToday(
      parse(urlStartDateString, "yyyy-MM-dd", new Date())
    );

    const fetchAISummary = async () => {
      setIsLoadingAISummary(true);
      try {
        const response = await getAISummary({
          server: SERVER,
          hubId,
          orgId,
          date: urlStartDateString,
          origin: "web",
        });
        if (response.ok && response.body) {
          setAISummary(response.body);
          const isHelpful = response.body.thumbsUp.includes(userKey);
          const noRatingGiven =
            !isHelpful && !response.body.thumbsDown.includes(userKey);
          setIsHelpful(noRatingGiven ? null : isHelpful);
          return;
        }
      } catch (error) {
        console.error("Error fetching AI summary:", error);
        setAISummary({
          summary: "No AI summary available for this day.",
          timestamp: "N/A",
          prompt: "N/A",
        });
      } finally {
        setIsLoadingAISummary(false);
      }
    };

    if (isNotToday && userKey && hubId && orgId && urlStartDateString) {
      fetchAISummary();
    }
  }, [urlStartDateString, userKey, hubId, orgId]);

  const { width } = useViewport();
  const onMobileView = width <= mobileBreakpoint;

  const [showingEventLog, setShowEventLog] = useState(true);

  const noEvents = events?.length === 0;

  const noDataText =
    "If this user has recently installed the Lilli hub it may take up to 48 hours to start seeing data. If install was more than 48 hours ago please contact your Lilli Administrator. If you think there should be data and there is not, please email us at: support@intelligentlilli.com";

  return (
    <div className={styles.container}>
      {onMobileView && (
        <div className={styles.toggle}>
          <Toggle
            clickRight={() => setShowEventLog(false)}
            clickLeft={() => setShowEventLog(true)}
            leftSelected={showingEventLog}
            leftText="Event log"
            rightText="Insights"
          />
        </div>
      )}

      {(!onMobileView || showingEventLog) && (
        <div className={styles.eventlog_container}>
          <EventLogNew
            currentTab={currentTab}
            events={events}
            noSplitting={noSplitting}
            noData={noEvents}
            isBathroom={isBathroom}
            isNewVariant={isNewVariant}
            isOverview={isOverview}
            isNighttime={isNighttime}
          />
        </div>
      )}

      {(!onMobileView || !showingEventLog) && (
        <div className={styles.insights}>
          {onMobileView && noData && (
            <>
              <h2>Insights</h2>
              <NoDataAvailable />
            </>
          )}

          {
            <>
              <h2>Insights</h2>
              {!noData && (
                <p className={styles.insights_context}>{insightsContext}</p>
              )}
              {noData && (
                <NoDataBox title="No data available" text={noDataText} />
              )}
            </>
          }

          {!noData && (
            <div className={styles.insights_inner}>
              <div className={styles.insights_grid}>
                {insights?.map((insight, index) => (
                  <Insight
                    insight={insight}
                    key={`${insight?.title}-${index}-dayview`}
                  />
                ))}
              </div>
              <div className={styles.ai_summary}>
                <AISummary
                  isLoading={isLoadingAISummary}
                  aiSummary={aiSummary}
                  hasAiSummaryForToday={hasAiSummaryForToday}
                  isHelpful={isHelpful}
                  setIsHelpful={setIsHelpful}
                  hubId={hubId}
                  urlStartDateString={urlStartDateString}
                />
              </div>
            </div>
          )}
          {children}
        </div>
      )}
    </div>
  );
};

export default SUDayView;
