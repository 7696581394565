import React, { useState } from "react";
import styles from "./styles.module.scss";
import theme from "../../Styles/theme.scss";
import { useSelector, useDispatch } from "react-redux";
import ToggleOnOff from "../ToggleOnOff";
import DropDownSelect from "../DropDownSelect";
// API
import {
  putAISettingsToggleAll,
  putAISettingsToggleOrg,
  putAISettingsToggleHub,
} from "@intelligentlilli/api-layer";
// State
import { updateIsOnForAllOrgs } from "../../State/slices/ai-settings-slice";
// Hooks
import { useGetDropDownOrgAndHubs } from "../../Services/Hooks";

const AISettingsDropDown = ({ settingsFetched, aiSettingsError }) => {
  const server = useSelector((state) => state.session.server);
  const dispatch = useDispatch();

  // get the initial list
  const aiEnabledForAll = useSelector(
    (state) => state.ai?.settings?.aiSummariesEnabled
  );

  // Drop down list for orgs and hubs
  const {
    orgs,
    hubs,
    selectedOrg,
    selectedHub,
    handleOrgChange,
    handleHubChange,
    setSelectedOrg,
    setSelectedHub,
    setOrgs,
    setHubs,
  } = useGetDropDownOrgAndHubs();

  // Response error handling
  const [errorAll, setErrorAll] = useState(null);
  const [errorOrg, setErrorOrg] = useState(null);
  const [errorHub, setErrorHub] = useState(null);

  // ---- Toggle, send requests, handle response and errors ----
  const toggleState = (apiCall, errorSetter, undoChanges) => {
    apiCall()
      .then((res) => {
        if (!res.ok) {
          errorSetter("Could not update settings. Try again.");
          undoChanges();
        } else {
          errorSetter(null);
        }
      })
      .catch((err) => {
        console.error("API error:", err);
        undoChanges();
        errorSetter("Could not update settings. Try again.");
      });
  };

  const toggleAll = () => {
    // If there's an error in the response, revert changes in the UI by updating local state
    const undoAllChanges = () =>
      dispatch(updateIsOnForAllOrgs((prevValue) => !prevValue));
    dispatch(updateIsOnForAllOrgs((prevValue) => !prevValue));
    // Make the request and handle the response
    toggleState(
      () =>
        putAISettingsToggleAll({
          server,
          enabled: !aiEnabledForAll,
          origin: "web",
          cookie: "",
        }),
      setErrorAll,
      undoAllChanges
    );
  };

  const toggleOrg = () => {
    if (!selectedOrg) return;
    const prevValue = selectedOrg.aiSummariesEnabled;
    // Helper function - If there is an error in the response, revert changes in the UI by updating local state
    const undoOrgChanges = () => {
      setOrgs((prevState) =>
        prevState.map((org) =>
          org.id === selectedOrg.id
            ? { ...org, aiSummariesEnabled: prevValue }
            : org
        )
      );
      setSelectedOrg((selectedOrg) => ({
        ...selectedOrg,
        aiSummariesEnabled: prevValue,
      }));
    };
    // Set changes in UI via local state
    const updateSelectedOrg = () => {
      setOrgs((prevState) =>
        prevState.map((org) =>
          org.id === selectedOrg.id
            ? { ...org, aiSummariesEnabled: !org.aiSummariesEnabled }
            : org
        )
      );
      setSelectedOrg((selectedOrg) => ({
        ...selectedOrg,
        aiSummariesEnabled: !selectedOrg.aiSummariesEnabled,
      }));
    };
    updateSelectedOrg();
    // Make the request and handle the response
    toggleState(
      () =>
        putAISettingsToggleOrg({
          server,
          orgId: selectedOrg.id,
          enabled: !prevValue,
          origin: "web",
          cookie: "",
        }),
      setErrorOrg,
      undoOrgChanges
    );
  };

  const toggleHub = () => {
    if (!selectedHub) return;
    // Helper function - If there is an error in the response, revert changes in the UI by updating local state
    const prevValue = selectedHub.aiSummariesEnabled;
    const undoHubChanges = () => {
      const revertedHubs = hubs.map((hub) =>
        hub.id === selectedHub.id
          ? { ...hub, aiSummariesEnabled: prevValue }
          : hub
      );
      setHubs(revertedHubs);
      setSelectedHub((prevHub) => ({
        ...prevHub,
        aiSummariesEnabled: prevValue,
      }));
    };
    // Set changes in UI via local state
    const updateSelectedHub = () => {
      const toggledHubs = hubs.map((hub) =>
        hub.id === selectedHub.id
          ? { ...hub, aiSummariesEnabled: !hub.aiSummariesEnabled }
          : hub
      );
      setHubs(toggledHubs);
      setSelectedHub((selectedHub) => ({
        ...selectedHub,
        aiSummariesEnabled: !selectedHub.aiSummariesEnabled,
      }));
      setOrgs((prevState) =>
        prevState.map((org) =>
          org.id === selectedOrg.id ? { ...org, hubs: toggledHubs } : org
        )
      );
    };
    updateSelectedHub();
    // Make the request and handle the response
    toggleState(
      () =>
        putAISettingsToggleHub({
          server,
          hubId: selectedHub.id,
          enabled: !prevValue,
          origin: "web",
          cookie: "",
        }),
      setErrorHub,
      undoHubChanges
    );
  };

  return (
    <>
      {settingsFetched && aiSettingsError && (
        <div className={styles.settings_error}>{aiSettingsError}</div>
      )}
      {settingsFetched && !aiSettingsError && (
        <div>
          <div className={styles.all_orgs}>
            <div className={styles.all_org_settings}>
              <div className={styles.kill_switch_title}>For everyone</div>
              <ToggleOnOff
                isEnabled={aiEnabledForAll}
                onToggleOn={toggleAll}
                onToggleOff={toggleAll}
                backgroundColor={theme.support2}
              />
            </div>
            <div className={styles.response_error}>{errorAll && errorAll}</div>
          </div>
          <div className={styles.orgs_and_hubs}>
            <div className={styles.outer_container}>
              <div className={styles.drop_down_label}>Organisations</div>
              <div className={styles.inner_container}>
                <div className={styles.drop_down}>
                  <DropDownSelect
                    options={orgs}
                    onChange={handleOrgChange}
                    placeholder="Organisations"
                    name="organisations"
                    width={400}
                    marginRight={30}
                    getOptionLabel={(option) => (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span>{option.label}</span>
                        <span>{option.aiSummariesEnabled ? "on" : "off"}</span>
                      </div>
                    )}
                    getOptionValue={(option) => option.value}
                  />
                </div>
                <ToggleOnOff
                  isEnabled={selectedOrg?.aiSummariesEnabled}
                  onToggleOn={toggleOrg}
                  onToggleOff={toggleOrg}
                  backgroundColor={theme.support2}
                />
              </div>
              <div className={styles.response_error}>
                {errorOrg && errorOrg}
              </div>
            </div>
            <div className={styles.outer_container}>
              <div className={styles.drop_down_label}>Hubs</div>
              <div className={styles.inner_container}>
                <div className={styles.drop_down}>
                  <DropDownSelect
                    options={hubs}
                    onChange={handleHubChange}
                    placeholder="Hubs"
                    name="hubs"
                    width={400}
                    marginRight={30}
                    getOptionLabel={(option) => (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span>{option.label}</span>
                        <span>{option.aiSummariesEnabled ? "on" : "off"}</span>
                      </div>
                    )}
                    getOptionValue={(option) => option.value}
                  />
                </div>
                <ToggleOnOff
                  isEnabled={selectedHub?.aiSummariesEnabled}
                  onToggleOn={toggleHub}
                  onToggleOff={toggleHub}
                  backgroundColor={theme.support2}
                />
              </div>
              <div className={styles.response_error}>
                {errorHub && errorHub}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AISettingsDropDown;
