import React from "react";
import styles from "./styles.module.scss";

const SettingsHeader = ({ title, description }) => {
  return (
    <div>
      <div className={styles.dashboard_header_container}>
        <div>
          <div className={styles.page_title}>{title}</div>
          <div className={styles.user_info}>{description}</div>
        </div>
      </div>
    </div>
  );
};

export default SettingsHeader;
